import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

interface AppPaginationProps {
  pageCount: number;
}

const AppPagination: React.FC<AppPaginationProps> = ({ pageCount }) => {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', value.toString());
    navigate(`?${searchParams.toString()}`);
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const prevPage = searchParams.get('page');
    if (prevPage) {
      setCurrentPage(Number(prevPage));
    }
  }, [location.search]);

  return (
    <Box sx={{ width: '100%', display: 'flex', my: 3, justifyContent: 'end' }}>
      <Pagination
        page={currentPage}
        onChange={handleChange}
        count={pageCount}
      />
    </Box>
  );
};

export default AppPagination;