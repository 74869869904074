import React, { memo, useEffect, useCallback } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import GradeSelect from "../academicClassElements/GradeSelect";
import AcademicYearSelect from "../academicClassElements/AcademicYearSelect";
import { AcademicClassSubjectType, CalculateRankData } from "../../types";
import { useAppDispatch } from "../../hooks";
import SubjectMultiSelectByGradeId from "../academicClassElements/SubjectMultiSelectByGradeId";
import { Description } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import ExamNameSelect from "../teacher/ExamNameSelect";
import { calculateRanks, downloadRank } from "../../slices/rankSlice";

interface FormValues {
  academicYear: { id: number; academic_year: string };
  grade: { id: number; grade: string };
  subjects: AcademicClassSubjectType[];
  exam_full_name: string;
}

const schema = yup.object().shape({
  academicYear: yup.object().required("Academic year is required"),
  grade: yup.object().required("Grade is required"),
  subjects: yup
    .array()
    .of(yup.object())
    .min(1, "At least one subject must be selected")
    .required("Subjects are required"),
  exam_full_name: yup.string().required("Exam name is required"),
});

const ExecuteRank: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const methods = useForm<any>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const {
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = methods;

  const formatData = useCallback(
    (data: FormValues): CalculateRankData => ({
      academic_year_id: data.academicYear.id,
      class_grade_id: data.grade.id,
      exam_full_name: data.exam_full_name,
      subject_ids: data.subjects.map((subj) => subj.subject_id),
    }),
    []
  );

  const fetchData = useCallback(
    async (formData: CalculateRankData) => {
      const pageNo = new URLSearchParams(window.location.search).get("page");
      await dispatch(
        calculateRanks({ payload: { pageNo: pageNo, data: formData } })
      );
    },
    [dispatch]
  );

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data) => {
      const formattedData = formatData(data);
      await fetchData(formattedData);
    },
    [fetchData, formatData]
  );

  const handleDownload = useCallback(async () => {
    const values = getValues();
    if(values.grade && values.exam_full_name && values.subjects.length > 0 && values.exam_full_name){
      const formattedData = {
        per_page: 20,
        page: new URLSearchParams(search).get("page") ? parseInt(new URLSearchParams(search).get("page")!) : 1,
        academic_year_id: values.academicYear.id,
        class_grade_id: values.grade.id,
        exam_full_name: values.exam_full_name,
        subject_ids: values.subjects.map((subj: any) => subj.subject_id),
      }
      await dispatch(downloadRank(formattedData));
    }else{
      alert("Please choose the academic year, grade, exam and subjects first!")
    }
  }, [dispatch, getValues, search]);

  useEffect(() => {
    const values = getValues();
    if (
      values.academicYear &&
      values.grade &&
      values.exam_full_name &&
      values.subjects
    ) {
      const formattedData = formatData(values);
      fetchData(formattedData);
    }
  }, [search, fetchData, formatData, getValues]);

  const selectedGrade = watch("grade");

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Button
            sx={{ mr: 2 }}
            color="success"
            type="button"
            variant="outlined"
            startIcon={<Description />}
            onClick={handleDownload}
          >
            Export As Excel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {t("calculate")}
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <AcademicYearSelect
              error={errors?.academicYear?.message?.toString()}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <GradeSelect
              error={errors?.grade?.message?.toString()}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ExamNameSelect size="small" error={errors?.exam_full_name?.message?.toString()} />
          </Grid>
          <Grid item xs={12}>
            {selectedGrade?.id && (
              <SubjectMultiSelectByGradeId
                showAllOption={true}
                size="small"
                gradeId={selectedGrade.id}
                error={errors?.subjects?.message?.toString()}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default memo(ExecuteRank);