import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL, getAuth, getHeaders } from "../utils/config";
import { showToast } from "../hooks/showToast";
import { BankAccType, ExperienceType } from "../types";
import toast from "react-hot-toast";

interface TeacherStateType {
  loading: boolean;
  data: any;
  error: any | null;
  experiences: ExperienceType[];
  bankAccs: BankAccType[];
}

const initialState: TeacherStateType = {
  loading: false,
  data: null,
  error: null,
  experiences: [],
  bankAccs: [],
};

export interface TeacherPayload {
  id?: number;
  data?: any;
  wantToShowToast?: boolean;
}

const buildUrl = (
  baseUrl: string,
  {
    id,
    action,
    pageNo,
    classGradeId,
    classRoomId,
    schoolId,
    searchKeyword,
    academicYearId,
  }: {
    id?: number;
    action: string;
    pageNo?: string | null;
    classGradeId?: string | null;
    classRoomId?: string | null;
    schoolId?: string | null;
    searchKeyword?: string | null;
    academicYearId?: number;
  }
): string => {
  const url = new URL(`${baseUrl}/teachers${id ? `/${id}` : ""}/${action}`);
  if (action === "all") url.searchParams.append("per_page", "20");
  if (classGradeId) url.searchParams.append("class_grade_id", classGradeId);
  if (classRoomId) url.searchParams.append("class_room_id", classRoomId);
  if (schoolId) url.searchParams.append("school_id", schoolId);
  if (searchKeyword) url.searchParams.append("search", searchKeyword);
  if (academicYearId) url.searchParams.append("academic_year_id", academicYearId.toString());
  if (pageNo) url.searchParams.append("page", pageNo);

  return url.toString();
};

export const teacherCrud = createAsyncThunk(
  "teacher/teacherCrud",
  async (
    {
      method,
      action,
      payload,
    }: { method: string; action: string; payload?: TeacherPayload },
    { rejectWithValue }
  ) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const pageNo = searchParams.get("page");
      const classGradeId = searchParams.get("class_grade_id");
      const classRoomId = searchParams.get("class_room_id");
      const schoolId = searchParams.get("school_id");
      const searchKeyword = searchParams.get("keyword");
      const defaultAcademicYear = getAuth()?.defaultAcademicYear?.id;
      const academicYearId = Number(searchParams.get("academic_year_id")) || defaultAcademicYear;
      if(action === "all" && !schoolId) return;
      const url = method.toLowerCase() === "get" ? buildUrl(BASE_URL, {
        pageNo,
        id: payload?.id,
        action,
        classGradeId,
        classRoomId,
        schoolId,
        searchKeyword,
        academicYearId,
      }) : `${BASE_URL}/teachers${payload?.id ? `/${payload.id}` : ""}/${action}`;

      const headers = getHeaders();
      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        data: payload?.data || {},
      };

      const response = await axios(config);
      if(!(action === "update" && payload?.wantToShowToast === false)){
        showToast(action);
      }
      return response.data;
    } catch (error: any) {   
      const errorMessage = error?.response?.data?.response?.message || error.message || "An error occurred";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const teacherSlice = createSlice({
  name: "teacher",
  initialState,
  reducers: {
    replaceExperience: (state, action: PayloadAction<ExperienceType[]>) => {
      state.experiences = action.payload;
    },
    addExp: (state, action: PayloadAction<ExperienceType>) => {
      state.experiences.push(action.payload);
    },
    removeExp: (state, action: PayloadAction<{ id: string | number }>) => {
      state.experiences = state.experiences.filter(
        (exp) => exp.id !== action.payload.id
      );
    },
    replaceBankAcc: (state, action: PayloadAction<BankAccType[]>) => {
      state.bankAccs = action.payload;
    },
    addBankAcc: (state, action: PayloadAction<BankAccType>) => {
      state.bankAccs.push(action.payload);
    },
    removeBankAcc: (state, action: PayloadAction<{ id: string | number }>) => {
      state.bankAccs = state.bankAccs.filter(
        (bankAcc) => bankAcc.id !== action.payload.id
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teacherCrud.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(teacherCrud.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(teacherCrud.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  replaceExperience,
  addExp,
  removeExp,
  replaceBankAcc,
  addBankAcc,
  removeBankAcc,
} = teacherSlice.actions;

export default teacherSlice.reducer;
