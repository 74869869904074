import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL, getHeaders } from "../utils/config";
import { BasicStateTypes, CalculateRankData } from "../types";
import toast from "react-hot-toast";

const initialState: BasicStateTypes = {
  loading: false,
  data: null,
  error: null,
};

export interface RankCalculationPayload {
  data: CalculateRankData;
  pageNo?: string | null;
}

export interface DownloadRankPayload {
  per_page: number;
  page: number;
  academic_year_id: number;
  class_grade_id: number;
  exam_full_name: string;
  subject_ids: number[];
}

export const calculateRanks = createAsyncThunk<
  any,
  { payload: RankCalculationPayload },
  { rejectValue: string }
>(
  "rank/calculateRank",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const url = `${BASE_URL}/students/calculate-rank`;
      
      const config: AxiosRequestConfig = {
        method: "POST",
        url,
        headers,
        params: { page: payload?.pageNo },
        data: payload?.data || {},
      };

      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error(error);
      const errorMessage = error.response?.data?.response?.message || error.message || "An error occurred";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const downloadRank = createAsyncThunk<
  void,
  DownloadRankPayload,
  { rejectValue: string }
>(
  "rank/downloadRank",
  async (payload, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const url = `${BASE_URL}/students/download-rank`;
      
      const config: AxiosRequestConfig = {
        method: "POST",
        url,
        headers,
        responseType: "blob",
        data: payload,
      };
      const response = await axios(config);
      const blob = new Blob([response.data], { type: response.headers["content-type"] });
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `rank_data_${new Date().toISOString()}.xlsx`;
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(blobUrl);
    } catch (error: any) {
        let errorMessage = "An error occurred";
        if (error.response?.data instanceof Blob) {
            const blob = error.response.data;
            const text = await blob.text(); 
            const errorData = JSON.parse(text);
            errorMessage = errorData.response?.message || error.message || "An error occurred";
        } else {
            errorMessage = error.response?.data?.response?.message || error.message || "An error occurred";
        }
        toast.error(errorMessage);
        return rejectWithValue(errorMessage);
    }
  }
);

export const rankSlice = createSlice({
  name: "rank",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(calculateRanks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(calculateRanks.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload || [];
      })
      .addCase(calculateRanks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to calculate ranks";
      })
      .addCase(downloadRank.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadRank.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(downloadRank.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to download ranks";
      });
  }
  
});

export default rankSlice.reducer;
