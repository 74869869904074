import React from 'react';
import { Box, Dialog, DialogContent, IconButton, Slide, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import { useLocation } from 'react-router-dom';

export interface AppDialogProps {
  title?: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  children: React.ReactNode;
  isFullScreen?: boolean;
}

// Create a Slide Transition component
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppDialog: React.FC<AppDialogProps> = ({ 
  isFullScreen = false,
  title, 
  open, 
  setOpen, 
  children 
}) => {
  const { pathname } = useLocation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullScreen={isFullScreen}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby={title ? "dialog-title" : undefined}
      aria-describedby={title ? "dialog-description" : undefined}
    >
      {title && (
        <Box sx={{ px: 3, py: 1 }}>
          <Typography variant="h6">{title}</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </Box>
      )}
      <DialogContent
        sx={pathname === "/students" ? { p: 0, minWidth: 600 } : { }}
        id="dialog-description"
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default AppDialog;