import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import ProfileDetail from "../components/user/ProfileDetail";
import Ranks from "../pages/Ranks";
import FormList from "../pages/commentForm/FormList";

// main pages and routes
const Login = lazy(() => import("../pages/auth/Login"));
const Dashboard = lazy(() => import("../pages/Dashboard"));

// route modules
const StudentsList = lazy(() => import("../pages/student/StudentsList"));
const TeachersList = lazy(() => import("../pages/teacher/TeachersList"));
const Markings = lazy(() => import("../pages/MarkingScheme"));
// const CommentForms = lazy(() => import("../pages/CommentForms"));
// const AwardsList = lazy(() => import("../pages/AwardsList"));
const ClassNamesList = lazy(
  () => import("../pages/academicClassElement/ClassRoomsList")
);
const GradesList = lazy(
  () => import("../pages/academicClassElement/GradesList")
);
const SubjectsList = lazy(
  () => import("../pages/academicClassElement/SubjectsList")
);
const AddStudent = lazy(() => import("../pages/student/AddStudent"));
const AddTeacher = lazy(() => import("../pages/teacher/AddTeacher"));
const StudentView = lazy(() => import("../pages/student/StudentView"));
const EditStudent = lazy(() => import("../pages/student/EditStudent"));
const TeacherView = lazy(() => import("../pages/teacher/TeacherView"));
const EditTeacher = lazy(() => import("../pages/teacher/EditTeacher"));
const SchoolsList = lazy(() => import("../pages/school/SchoolsList"));
const PrincipalsList = lazy(() => import("../pages/principal/PrincipalsList"));
const CreatePrincipal = lazy(
  () => import("../pages/principal/CreatePrincipal")
);
const SystemSetting = lazy(() => import("../pages/school/SystemSetting"));
const ManageMarkingScheme = lazy(
  () => import("../components/markingScheme/ManageMarkingScheme")
);
const AuthRoutes = lazy(() => import("./AuthRoutes"));
const PrivateRoutes = lazy(() => import("./PrivateRoutes"));

const RedirectPrivateRoutes: React.FC = () => {
  const { page } = useParams<{ page: string }>();

  switch (page) {
    case undefined:
    case "":
      return <Dashboard />;
    case "marking-schemes":
      return <Markings />;
    case "classrooms":
      return <ClassNamesList />;
    case "grades":
      return <GradesList />;
    case "subjects":
      return <SubjectsList />;
    case "ranks":
      return <Ranks />;
    case "comment-forms":
      return <FormList />;
    default:
      return <p>Page not found</p>;
  }
};

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<AuthRoutes />}>
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="/" element={<PrivateRoutes />}>
            <Route index element={<Dashboard />} />
            <Route
              path="profile"
              element={
                <Suspense fallback={<Loading />}>
                  <Routes>
                    <Route index element={<ProfileDetail />} />
                  </Routes>
                </Suspense>
              }
            />
            <Route
              path="schools/*"
              element={
                <Suspense fallback={<Loading />}>
                  <Routes>
                    <Route index element={<SchoolsList />} />
                    <Route
                      path="system-setting/:id"
                      element={<SystemSetting />}
                    />
                  </Routes>
                </Suspense>
              }
            />
            <Route
              path="students/*"
              element={
                <Suspense fallback={<Loading />}>
                  <Routes>
                    <Route index element={<StudentsList />} />
                    <Route path="add-new-student" element={<AddStudent />} />
                    <Route path="view/:id" element={<StudentView />} />
                    <Route path="edit/:id" element={<EditStudent />} />
                  </Routes>
                </Suspense>
              }
            />
            <Route
              path="teachers/*"
              element={
                <Suspense fallback={<Loading />}>
                  <Routes>
                    <Route index element={<TeachersList />} />
                    <Route path="add-new-teacher" element={<AddTeacher />} />
                    <Route path="view/:id" element={<TeacherView />} />
                    <Route path="edit/:id" element={<EditTeacher />} />
                  </Routes>
                </Suspense>
              }
            />
            <Route
              path="principals/*"
              element={
                <Suspense fallback={<Loading />}>
                  <Routes>
                    <Route index element={<PrincipalsList />} />
                    <Route
                      path="add-new-principal"
                      element={<CreatePrincipal />}
                    />
                  </Routes>
                </Suspense>
              }
            />
            <Route
              path="marking-schemes/*"
              element={
                <Suspense fallback={<Loading />}>
                  <Routes>
                    <Route index element={<Markings />} />
                    <Route
                      path="add-new-marking-scheme"
                      element={<ManageMarkingScheme />}
                    />
                    <Route
                      path="update-marking-scheme"
                      element={<ManageMarkingScheme />}
                    />
                  </Routes>
                </Suspense>
              }
            />
            <Route path=":page" element={<RedirectPrivateRoutes />} />
          </Route>
          <Route path="*" element={<p>Page not found</p>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
