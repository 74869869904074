import { Box, Card, CardContent, Collapse, Grid, IconButton, List, ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ExecuteRank from '../components/rank/ExecuteRank'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../hooks'
import { RootState } from '../store'
import AppPagination from '../components/AppPagination'
import Loading from '../components/Loading'
import { RankResponseType } from '../types'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import NoDataAlert from '../components/NoDataAlert'

const Ranks: React.FC = () => {
  const [ranksData, setRankData] = useState<RankResponseType[]>([])
  const [openRows, setOpenRows] = useState<number[]>([])
  const { data: ranks, loading } = useAppSelector((state: RootState) => state.rank)
  const { t } = useTranslation()
  const [lastPage, setLastPage] = useState<number>(1)

  useEffect(() => {
    if (ranks) {
      const { meta, data: rankRes } = ranks
      setRankData(rankRes)
      setLastPage(Number(meta?.total_pages) || 1)
    }
  }, [ranks])

  const toggleRow = (index: number) => {
    setOpenRows((prevOpenRows) =>
      prevOpenRows.includes(index)
        ? prevOpenRows.filter((row) => row !== index)
        : [...prevOpenRows, index]
    )
  }

  return (
    <Grid item xs={12}>
      {loading && <Loading />}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Card elevation={0} sx={{ borderRadius: "10px", flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <ExecuteRank />
            {/* results table */}
            <TableContainer sx={{ mt: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>{t("rank")}</TableCell>
                    <TableCell>{t("name")}</TableCell>
                    <TableCell align='center'>{t("school")}</TableCell>
                    <TableCell align='center'>{t("class grade")}</TableCell>
                    <TableCell>{t("room name")}</TableCell>
                    <TableCell align='center'>{t("specific total")}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ranksData && ranksData?.length > 0 ? (
                    ranksData.map((rank, index) => (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell
                            align='center'
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '1.2rem',
                            }}
                          >
                            {rank.rank}
                          </TableCell>
                          <TableCell>{rank.name}</TableCell>
                          <TableCell align='center'>{rank.school_name}</TableCell>
                          <TableCell align='center'>{rank.class_grade_name}</TableCell>
                          <TableCell>{rank.class_room_name}</TableCell>
                          <TableCell align='center'>{rank.specific_total}</TableCell>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => toggleRow(index)}
                            >
                              {openRows.includes(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                            <Collapse in={openRows.includes(index)} timeout="auto" unmountOnExit>
                              <List sx={{ ml: 10 }}>
                                {Object.keys(rank.marks).map((key) => (
                                  <ListItem key={key}>
                                    {key} - <Typography sx={{ fontSize: "16px", mx: 2 }}>{rank.marks[key]} Marks</Typography>
                                  </ListItem>
                                ))}
                              </List>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  ) : (
                    <NoDataAlert colSpan={10} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <AppPagination pageCount={Number(lastPage)} />
          </CardContent>
        </Card>
      </Box>
    </Grid>
  )
}

export default Ranks