import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL, getHeaders } from "../utils/config";
import { BasicStateTypes } from "../types";
import toast from "react-hot-toast";

interface CommentFormPayload {
  id?: number;
  method: AxiosRequestConfig['method'];
  action: string;
  data?: any;
  studentId?: string;
  formId?: string;
}

interface CommentFormState extends BasicStateTypes {
  data: any | null;
}

const initialState: CommentFormState = {
  loading: false,
  data: null,
  error: null,
};

export const commentFormCrud = createAsyncThunk(
  "commentForm/commentFormCrud",
  async ({ method, action, id, data }: CommentFormPayload, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const pageNo = searchParams.get("page");

      const url = new URL(`${BASE_URL}/comment-forms${id ? `/${id}` : ""}/${action}`);
      if (action === "all") url.searchParams.append("per_page", "20");
      if (pageNo) url.searchParams.append("page", pageNo);

      const headers = getHeaders();
      const config: AxiosRequestConfig = {
        method,
        url: url.toString(),
        headers,
        data: data || {},
      };
      const response = await axios(config);
      if (action === "create") toast.success("Successfully created", { duration: 3000 });
      if (action === "update") toast.success("Successfully updated", { duration: 3000 });
      if (action === "delete") toast.success("Successfully deleted", { duration: 3000 });
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || error.message || "An error occurred";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const commentFormDataCrud = createAsyncThunk(
  "commentForm/commentFormDataCrud",
  async ({ method, action, data, id, studentId, formId }: CommentFormPayload, { rejectWithValue }) => {
    try {
      const url = new URL(`${BASE_URL}/comment-form-data${id ? `/${id}` : ""}/${action}`);
      if (studentId) url.searchParams.append("student_id", studentId);
      if (formId) url.searchParams.append("form_id", formId);

      const headers = getHeaders();
      const config: AxiosRequestConfig = {
        method,
        url: url.toString(),
        headers,
        data: data || {},
      };

      const response = await axios(config);
      
      if (action === "create") toast.success("Data successfully created", { duration: 3000 });
      if (action === "update") toast.success("Data successfully updated", { duration: 3000 });
      if (action === "delete") toast.success("Data successfully deleted", { duration: 3000 });
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.response?.message || error.message || "An error occurred";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const commentFormSlice = createSlice({
  name: "commentForm",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(commentFormCrud.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(commentFormCrud.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(commentFormCrud.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to perform operation";
      })
      .addCase(commentFormDataCrud.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(commentFormDataCrud.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(commentFormDataCrud.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to perform data operation";
      });
  },
});

export default commentFormSlice.reducer;