import { Fab, SvgIconTypeMap, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface Props {
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  color:
    | "inherit"
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  onClick: () => void;
}

const ActionButton: React.FC<Props> = ({ title, icon: Icon, color, onClick }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(title)}>
      <Fab onClick={onClick} sx={{ borderRadius: "4px", mx: 1 }} size="small" color={color}>
        <Icon />
      </Fab>
    </Tooltip>
  );
};

export default ActionButton;
