import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AlertDialogProps {
  toggle: boolean;
  setToggle: (value: boolean) => void;
  cancel: () => void;
  confirm: () => Promise<void>;
  content: string;
  title: string;
  type: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

const AlertDialog: React.FC<AlertDialogProps> = ({ toggle, setToggle, cancel, confirm, content, title, type }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setToggle(false);
  };

  React.useEffect(() => {
    setOpen(toggle);
  }, [toggle]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography align='center' variant='h5'>{title}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ width: 400 }}>
          <DialogContentText sx={{ textAlign: 'justify' }} id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button sx={{ mr: 2 }} color={type} onClick={cancel}>{t('cancel')}</Button>
          <Button variant='contained' color={type} onClick={confirm} autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AlertDialog;