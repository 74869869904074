import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Axios, { AxiosRequestConfig } from "axios";
import { BASE_URL, getAuth, getHeaders } from "../utils/config";
import toast from "react-hot-toast";

interface DownloadPayload {
  url: string;
  filename: string;
}

interface GetUrlPayload {
  type: "teacher" | "student";
  ids: number[];
}

interface DownloadReportCardPayload {
  student_ids: number[];
}

interface DownloadState {
  loading: boolean;
  error: string | null;
  success: boolean;
  downloadUrl: string | null;
}

interface DownloadCommentFormPayload {
  student_ids: number[];
  form_id: string;
}

export const getCommentFormDownloadUrl = createAsyncThunk<
  string,
  DownloadCommentFormPayload,
  { rejectValue: string }
>("download/commentFormUrl", async ({ student_ids, form_id }, { rejectWithValue }) => {
  const headers = getHeaders();
  const url = `${BASE_URL}/students/commentform/download`;

  const AxiosRequestConfig: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: headers,
    data: {
      student_ids: student_ids,
      form_id: form_id,
    },
  };

  try {
    const response = await Axios(AxiosRequestConfig);
    return response.data?.data?.url;
  } catch (error: any) {
    toast.error(error.response.data.response.message || "Failed to get comment form download URL");
    return rejectWithValue(error.message || "Failed to get comment form download URL");
  }
});

export const getDownloadUrl = createAsyncThunk<
  string,
  GetUrlPayload,
  { rejectValue: string }
>("download/getUrl", async ({ type, ids }, { rejectWithValue }) => {
  const headers = getHeaders();
  const url = `${BASE_URL}/${type}s/cards/download`;

  const AxiosRequestConfig: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: headers,
    data:
      type === "teacher"
        ? {
            teacher_ids: ids,
          }
        : {
            student_ids: ids,
          },
  };

  try {
    const response = await Axios(AxiosRequestConfig);
    return response.data?.data?.url;
  } catch (error: any) {
    toast.error(error.response.data.response.message || "Failed to get download URL");
    return rejectWithValue(error.message || "Failed to get download URL");
  }
});


export const getReportCardDownloadUrl = createAsyncThunk<
  string,
  DownloadReportCardPayload,
  { rejectValue: string }
>("download/reportCardUrl", async ({ student_ids }, { rejectWithValue }) => {
  const headers = getHeaders();
  const url = `${BASE_URL}/students/reportcard/download`;

  const AxiosRequestConfig: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: headers,
    data: {
      student_ids: student_ids,
    },
  };

  try {
    const response = await Axios(AxiosRequestConfig);
    return response.data?.data?.url;
  } catch (error: any) {
    toast.error(error.response.data.response.message || "Failed to get report card download URL");
    return rejectWithValue(error.message || "Failed to get report card download URL");
  }
});

export const downloadAtBlink = createAsyncThunk<
  void,
  DownloadPayload & { fileType: "pdf" | "png" },
  { rejectValue: string }
>("download/file", async ({ url, fileType }, { rejectWithValue }) => {
  const auth = getAuth();
  if (!url || !auth) {
    return rejectWithValue("Missing URL or Authorization header");
  }

  const AxiosRequestConfig: AxiosRequestConfig = {
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${auth?.accessToken}`,
    },
    responseType: "blob",
  };

  try {
    const response = await Axios(AxiosRequestConfig);
    const contentDisposition = response?.headers["content-disposition"];
    
    let filename = `downloaded_file.${fileType}`;
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="(.+)"/);
      if (filenameMatch && filenameMatch[1]) {
        filename = filenameMatch[1];
      }
    }
    const blob = new Blob([response.data], { type: fileType === "pdf" ? "application/pdf" : "image/png" });
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename.endsWith(`.${fileType}`) ? filename : `${filename}.${fileType}`;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
  } catch (error: any) {
    return rejectWithValue(error.message || "Download failed");
  }
});


// Initial state
const initialState: DownloadState = {
  loading: false,
  error: null,
  success: false,
  downloadUrl: null,
};

// Slice definition
const downloadCardSlice = createSlice({
  name: "download",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDownloadUrl.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
        state.downloadUrl = null;
      })
      .addCase(
        getDownloadUrl.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.success = true;
          state.downloadUrl = action.payload;
        }
      )
      .addCase(
        getDownloadUrl.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "Failed to get the download URL";
        }
      );

    builder
      .addCase(getReportCardDownloadUrl.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(
        getReportCardDownloadUrl.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.success = true;
          state.downloadUrl = action.payload;
        }
      )
      .addCase(
        getReportCardDownloadUrl.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "Failed to get the report card download URL";
        }
      );

    builder
      .addCase(downloadAtBlink.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(downloadAtBlink.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.downloadUrl = null;
      })
      .addCase(
        downloadAtBlink.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "Download failed";
        }
      )
      .addCase(getCommentFormDownloadUrl.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(
        getCommentFormDownloadUrl.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.success = true;
          state.downloadUrl = action.payload;
        }
      )
      .addCase(
        getCommentFormDownloadUrl.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || "Failed to get comment form download URL";
        }
      );
  },
});


export const downloadReducer = downloadCardSlice.reducer;