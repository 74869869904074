import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  forwardRef,
} from "react";
import { Autocomplete, TextField, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AcademicClassSubjectType } from "../../types";
import { useAppDispatch } from "../../hooks";
import { academicClassElementCrud } from "../../slices/academicClassElementSlice";
import { useFormContext } from "react-hook-form";

interface Props {
  oldValueIds?: number[];
  gradeId: number | null;
  error?: string;
  size?: "small" | "medium";
  showAllOption?: boolean;
}

const SubjectMultiSelectByGradeId = forwardRef<HTMLDivElement, Props>(
  function SubjectSelectByGradeId(
    { size = "medium", showAllOption = false, oldValueIds, error, gradeId },
    ref
  ) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { setValue } = useFormContext();
    const [resultData, setResultData] = useState<AcademicClassSubjectType[]>([]);
    const [selectedValues, setSelectedValues] = useState<
      AcademicClassSubjectType[]
    >([]);

    const fetchData = useCallback(
      async (gradeIdToFetch: number) => {
        const dispatchedData = await dispatch(
          academicClassElementCrud({
            method: "GET",
            action: `all?grade_id=${Number(gradeIdToFetch)}`,
            endPoint: "subjects",
          })
        );
        if (
          dispatchedData.meta.requestStatus === "fulfilled" &&
          dispatchedData.payload?.data
        ) {
          const subjects = dispatchedData.payload.data;

          if (oldValueIds && oldValueIds.length) {
            const filteredValues = subjects.filter(
              (subject: AcademicClassSubjectType) =>
                subject.id && oldValueIds.includes(subject.id)
            );
            setSelectedValues(filteredValues);
            setValue("subjects", filteredValues);
          } else {
            setSelectedValues([]);
            setValue("subjects", []);
          }

          setResultData(subjects);
        }
      },
      [dispatch, oldValueIds, setValue]
    );

    useEffect(() => {
      if (gradeId) {
        fetchData(gradeId);
      } else {
        setResultData([]);
        setSelectedValues([]);
        setValue("subjects", []);
      }
    }, [fetchData, gradeId, setValue]);

    // Insert "All" option if showAllOption is true
    const options = useMemo(() => {
      if (showAllOption && resultData.length > 0) {
        return [{ id: "all", subject_name: t("All Subjects") }, ...resultData];
      }
      return resultData;
    }, [resultData, showAllOption, t]);

    const handleSelectionChange = useCallback(
      (event: any, newValues: any) => {
        if (showAllOption) {
          // const allOption = options.find((option) => option.id === "all");
    
          if (newValues.some((value: any) => value.id === "all")) {
            if (selectedValues.length === resultData.length) {
              // If "All" is already selected and user clicks again, unselect everything
              setSelectedValues([]);
              setValue("subjects", []);
            } else {
              // Otherwise, select all subjects
              setSelectedValues(resultData);
              setValue("subjects", resultData);
            }
          } else {
            // If "All" was previously selected, and now the user selects individual subjects
            setSelectedValues(newValues);
            setValue("subjects", newValues);
          }
        } else {
          setSelectedValues(newValues);
          setValue("subjects", newValues);
        }
      },
      [resultData, selectedValues, setValue, showAllOption]
    );
    

    return (
      <Autocomplete
        size={size}
        ref={ref}
        multiple
        fullWidth
        value={selectedValues}
        onChange={handleSelectionChange}
        options={options}
        disableCloseOnSelect // Keep the dropdown open when selecting
        getOptionLabel={(option) => option.subject_name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              style={{ marginRight: 8 }}
              checked={(selected || option.id === "all") && selectedValues.length === resultData.length}
            />
            {option.subject_name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("select subject")}
            error={!!error}
            helperText={error}
          />
        )}
      />
    );
  }
);

export default SubjectMultiSelectByGradeId;