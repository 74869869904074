import { Box, Button } from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { CommentFormType } from '../../types';
import AcademicYearSelect from '../academicClassElements/AcademicYearSelect';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import ExamNameSelect from '../teacher/ExamNameSelect';
import MonthSelect from '../MonthSelect';
import { useAppDispatch } from '../../hooks';
import { commentFormCrud } from '../../slices/commentFormSlice';

interface Props {
    refetchData: () => Promise<void>,
    setOpen: Dispatch<SetStateAction<boolean>>;
    formDataToEdit: CommentFormType | null;
}

const ManageCommentForm: React.FC<Props> = ({ refetchData, setOpen, formDataToEdit }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const validationSchema = yup.object().shape({
        academicYear: yup.object().required(t("academic year is required")),
        exam_full_name: yup.string().required("Exam name is required"),
        month: yup.string().required("Month is required"),
    });

    const methods = useForm({
        resolver: yupResolver(validationSchema),
    });

    const handleOnSubmit = useCallback(async (data: any) => {
        const action = formDataToEdit ? 'update' : 'create';
        const method = 'POST';
        const payload = {
            method,
            action,
            id: formDataToEdit?.id,
            data: {
                academic_year_id: data.academicYear.id,
                exam_full_name: data.exam_full_name,
                month: data.month,
            },
        };
        try {
            await dispatch(commentFormCrud(payload)).unwrap();
            refetchData()
            methods.reset()
            setOpen(false);
        } catch (error) {
            console.error('Failed to save comment form:', error);
        }
    }, [dispatch, formDataToEdit, setOpen, refetchData, methods]);

    return (
        <Box sx={{ width: 350 }}>
            <FormProvider {...methods}>
                <form autoComplete="off" onSubmit={methods.handleSubmit(handleOnSubmit)}>
                    <AcademicYearSelect oldValueId={formDataToEdit?.academic_year_id} error={methods.formState.errors.academicYear?.message} />
                    <Box sx={{ mb: 2 }} />
                    <ExamNameSelect oldValue={formDataToEdit?.exam_full_name} error={methods.formState.errors?.exam_full_name?.message} />
                    <Box sx={{ mb: 2 }} />
                    <MonthSelect oldValue={formDataToEdit?.month} error={methods.formState.errors?.month?.message} />
                    <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button onClick={() =>{
                            methods.reset()
                            setOpen(false)
                        }} sx={{ mr: 2 }} type="button">
                            {t('cancel')}
                        </Button>
                        <Button type="submit" variant="contained">
                            {formDataToEdit ? t("update") : t('create')}
                        </Button>
                    </Box>
                </form>
            </FormProvider>
        </Box>
    );
};

export default ManageCommentForm;
