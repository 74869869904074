import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  oldValue?: string | null;
  error?: string | null;
  size?: "small" | "medium";
}

const MonthSelect: React.FC<Props> = ({ size = "medium", oldValue, error }) => {
  const { t } = useTranslation();
  const { setValue, trigger } = useFormContext();
  const [months] = useState<string[]>([
    t("January"), 
    t("February"), 
    t("March"), 
    t("April"), 
    t("May"), 
    t("June"), 
    t("July"), 
    t("August"), 
    t("September"), 
    t("October"), 
    t("November"), 
    t("December")
  ]);
  
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  useEffect(() => {
    if (selectedValue) {
      setValue('month', selectedValue);
      trigger('month');
    } else {
      setValue('month', null);
    }
  }, [selectedValue, setValue, trigger]);

  useEffect(() => {
    if (oldValue) {
      setSelectedValue(oldValue);
      setValue('month', oldValue);
    }
  }, [oldValue, setValue]);

  return (
    <Autocomplete
      size={size}
      fullWidth
      value={selectedValue}
      onChange={(event, newValue) => setSelectedValue(newValue)}
      options={months}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('select month')}
          error={!!error}
          helperText={error || ''}
        />
      )}
    />
  );
};

export default MonthSelect;