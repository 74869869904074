import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { BasicStateTypes } from '../types';
import { BASE_URL, getHeaders } from '../utils/config';
import toast from 'react-hot-toast';

interface FileUploadPayload {
  file: File;
  type: 'teacher' | 'student';
  academic_year_id?: number;
  school_id?: number;
}

interface FileUploadState extends BasicStateTypes {
  data: any | null;
}

const initialState: FileUploadState = {
  loading: false,
  data: null,
  error: null,
};

export const uploadFile = createAsyncThunk<
  any, 
  FileUploadPayload, 
  { rejectValue: string }
>(
  'file/uploadFile',
  async (params, { rejectWithValue }) => {
    try {
      const { file, type, academic_year_id, school_id } = params;
      const url = `${BASE_URL}/${type === 'teacher' ? 'teachers' : 'students'}/import`;
      const headers = getHeaders();

      const formData = new FormData();
      formData.append('file', file);
      if (academic_year_id) formData.append('academic_year_id', academic_year_id.toString());
      if (school_id) formData.append('school_id', school_id.toString());

      const config: AxiosRequestConfig = {
        method: 'POST',
        url,
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };

      const response = await axios(config);
      toast.success("File uploaded successfully");
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.response?.message || 'Failed to upload file';
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);


export const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadFile.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(uploadFile.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default fileUploadSlice.reducer;
