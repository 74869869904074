import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useNavigate, useLocation } from 'react-router-dom';

const GoBackBtn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState<any>(null);

  useEffect(() => {
    setPreviousLocation(location);
  }, [location]);

  const handleGoBack = () => {
    if(location.pathname.includes('system-setting')){
      navigate('/schools')
    }else if (previousLocation) {
      navigate(-1);
      
    }
  };

  return (
    <Box sx={{ position: 'fixed', bottom: 40, right: 50 }}>
      <Fab size='small' onClick={handleGoBack} color="primary" aria-label="go back">
        <KeyboardReturnIcon />
      </Fab>
    </Box>
  );
};

export default GoBackBtn;