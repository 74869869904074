import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { AcademicYearType } from '../../types';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { academicClassElementCrud } from '../../slices/academicClassElementSlice';

interface Props {
  oldValueId?: number | null;
  error?: string | undefined;
  size?: "small" | "medium";
}

const AcademicYearSelect: React.FC<Props> = ({ size = "medium", oldValueId, error }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setValue, trigger } = useFormContext();
  const { academicYears } = useAppSelector((state) => state.academicClassElement);
  
  const [selectedValue, setSelectedValue] = useState<AcademicYearType | null>(null);

  const fetchData = useCallback(async () => {
    if (academicYears.length === 0) {
      await dispatch(
        academicClassElementCrud({
          method: 'GET',
          action: 'all',
          endPoint: 'academic-years',
        })
      );
    }
  }, [academicYears.length, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (oldValueId && academicYears.length > 0) {
      const filteredValue = academicYears.find((val) => val.id === oldValueId) || null;
      setSelectedValue(filteredValue);
      setValue('academicYear', filteredValue);
    }
  }, [oldValueId, academicYears, setValue]);

  useEffect(() => {
    if (selectedValue) {
      setValue('academicYear', selectedValue);
      trigger('academicYear');
    }
  }, [selectedValue, setValue, trigger]);

  const options = useMemo(() => academicYears, [academicYears]);

  return (
    <Autocomplete
      size={size}
      value={selectedValue}
      onChange={(event, newValue) => setSelectedValue(newValue)}
      options={options}
      getOptionLabel={(option) => option.academic_year}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("academic year")}
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};

export default AcademicYearSelect;
