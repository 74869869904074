import React from 'react'
import { TableRow, TableCell, Alert, Grid, Typography} from '@mui/material'
import { useTranslation } from 'react-i18next'

interface NoDataAlertProps {
  colSpan?: number
  message?: string
  type?: 'table' | 'grid'
}

const NoDataAlert: React.FC<NoDataAlertProps> = ({
    colSpan = 10, 
    message, 
    type = 'table'
}) => {
  const { t } = useTranslation()
  const displayMessage = message || t("no data available")

  return type === 'table' ? (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        <Alert variant="standard" severity="info">
          {displayMessage}
        </Alert>
      </TableCell>
    </TableRow>
  ) : (
    <Grid item xs={12}>
      <Typography color="text.secondary" align="center">
        {displayMessage}
      </Typography>
    </Grid>
  )
}

export default NoDataAlert
