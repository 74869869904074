import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { AcademicClassElementType } from '../../types';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../hooks';
import { academicClassElementCrud } from '../../slices/academicClassElementSlice';

interface Props {
  oldValueId?: number | null;
  error?: string | undefined;
  size?: "small" | "medium";
  disabled?: boolean
}

const GradeSelect: React.FC<Props> = ({ size = "medium", oldValueId, error, disabled = false }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setValue, trigger } = useFormContext();
  const [resultData, setResultData] = useState<AcademicClassElementType[]>([]);
  const [selectedValue, setSelectedValue] = useState<AcademicClassElementType | null>(null);

  const fetchData = useCallback(async () => {
    const dispatchedData = await dispatch(
      academicClassElementCrud({
        method: 'GET',
        action: 'all',
        endPoint: 'classes/grades',
      })
    );

    if (dispatchedData.meta.requestStatus === 'fulfilled' && dispatchedData.payload?.data) {
      setResultData(dispatchedData.payload.data);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (oldValueId) {
      const filteredValue = resultData.find(val => val.id === oldValueId);
      setSelectedValue(filteredValue || null);
      setValue('grade', filteredValue || null);
    }
  }, [oldValueId, resultData, setValue]);

  useEffect(() => {
    setValue('grade', selectedValue);
    if (selectedValue) trigger('grade');
  }, [selectedValue, setValue, trigger]);

  const options = useMemo(() => resultData, [resultData]);

  return (
    <Autocomplete
      disabled={disabled}
      size={size}
      value={selectedValue}
      onChange={(event, newValue) => setSelectedValue(newValue)}
      options={options}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("select grade")}
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};

export default GradeSelect;
