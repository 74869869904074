import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks";
import AppPagination from "../../components/AppPagination";
import Loading from "../../components/Loading";
import { useLocation } from "react-router-dom";
import AlertDialog from "../../components/AlertDialog";
import theme from "../../utils/theme";
import ActionButton from "../../components/ActionButton"
import { commentFormCrud } from "../../slices/commentFormSlice";
import { CommentFormType } from "../../types";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import AppDialog from "../../components/AppDialog";
import ManageCommentForm from "../../components/commentForm/ManageCommentForm";
import NoDataAlert from '../../components/NoDataAlert'

const FormList: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { search } = useLocation();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [idToDel, setIdToDel] = useState<number | null>(null);
    const { data, loading } = useAppSelector((state) => state.commentForm);
    const [formsList, setFormsList] = useState<CommentFormType[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [lastPage, setLastPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(0);
    const [ editData, setEditData ] = useState<CommentFormType | null>(null)
    const [ manageOpen,setManageOpen ] = useState<boolean>(false)

    const fetchData = useCallback(async () => {
      const dispatchedData = await dispatch(
        commentFormCrud({
          method: "GET",
          action: "all",
        })
      );
      if (
        dispatchedData.meta.requestStatus === "fulfilled" &&
        dispatchedData.payload?.data
      ) {
        const { meta, data } = dispatchedData.payload;
        setFormsList(data);
        setLastPage(Number(meta?.total_pages) || 1);
        setCurrentPage(Number(meta?.current_page));
        setPerPage(Number(meta?.per_page));
      }
    }, [dispatch]);
  
    const handleDelete = (formId: number) => {
      setShowAlert(true);
      setIdToDel(formId);
    };
  
    const onCancel = () => {
      setShowAlert(false);
      setIdToDel(null);
    };
  
    const onConfirm = async () => {
        if (!idToDel) return;
        try {
          const dispatchData = await dispatch(
            commentFormCrud({
              method: "post",
              id: idToDel,
              data: { id: idToDel },
              action: "delete",
            })
          );
          if (dispatchData.meta.requestStatus === "fulfilled") {
            fetchData();
            setShowAlert(false);
          }
        } catch (error) {
          console.error("Delete error:", error);
        }
    };
      
    useEffect(() => {
      fetchData();
    }, [fetchData, search]);
  
    useEffect(() => {
      if (data) {
        setFormsList(data?.data || []);
      }
    }, [data]);
  
    return (
      <Grid item xs={12}>
        {loading && <Loading />}
        { 
            manageOpen && 
            <AppDialog title={editData ? t("update form") : t("add new form")} open={manageOpen} setOpen={setManageOpen}>
                <ManageCommentForm refetchData={fetchData} setOpen={setManageOpen} formDataToEdit={editData}/>
            </AppDialog>
        }
        {showAlert && (
          <AlertDialog
            type="error"
            title={"Are you sure?"}
            content={"You want to delete this form."}
            toggle={showAlert}
            setToggle={setShowAlert}
            cancel={onCancel}
            confirm={onConfirm}
          ></AlertDialog>
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Card
            elevation={0}
            sx={{
              borderRadius: "10px",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent
              sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
            >
              <Box>
                <Button
                  variant="contained"
                  onClick={() =>{
                    setEditData(null)
                    setManageOpen(true)
                  }}
                >
                  {t("add new form")}
                </Button>
              </Box>
              <AppPagination pageCount={Number(lastPage)} />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
                      <TableCell>{t("no")}</TableCell>
                      <TableCell>{t("form id")}</TableCell>
                      <TableCell align="center">{t("academic year")}</TableCell>
                      <TableCell>{t("exam name")}</TableCell>
                      <TableCell>{t("month")}</TableCell>
                      <TableCell align="center">{t("actions")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formsList.length > 0 ? (
                      formsList.map((form, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {(currentPage - 1) * perPage + (index + 1)}
                          </TableCell>
                          <TableCell>{form.form_id}</TableCell>
                          <TableCell align="center">{form.r_academic_year_name}</TableCell>
                          <TableCell>{form.exam_full_name}</TableCell>
                          <TableCell>{form.month}</TableCell>
                          <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                            <ActionButton
                              title="Edit form"
                              icon={EditIcon}
                              color="warning"
                              onClick={() =>{
                                setEditData(form)
                                setManageOpen(true)
                              }}
                            />
                            <ActionButton
                              title="Delete form"
                              icon={DeleteIcon}
                              color="error"
                              onClick={() => handleDelete(Number(form.id))}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                        <NoDataAlert colSpan={7} />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ mt: 2 }}>
                <AppPagination pageCount={Number(lastPage)} />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    );
  };
  
export default FormList;  